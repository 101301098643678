import React, {useState, useEffect, useRef} from "react"
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import { showErrorMessage } from "../../utility/Utilities";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import { ClickOutside } from "../../utility/Utilities";
import SmallDropdown from "../../components/SmallDropdown";
import { RiFileCopyLine } from "react-icons/ri";
import {TiArrowSortedDown} from "react-icons/ti";
import { ImUser } from "react-icons/im";
import {FaTrashAlt} from "react-icons/fa";
import "./ShareVideo.css"
import classNames from "classnames";

function ShareToInstagram ({fbLogin, fbUser, onClose, video, openShareTable}) {

    const { data, mutate: mutateCredentials } = useBackend("/socials/credentials/instagram")
    const credentialsData = data || []

    const [caption, setCaption] = useState("")
    const [mediaType, setMediaType] = useState("reels")
    const [shareToFeed, setShareToFeed] = useState(false)
    const [selectedCredential, setSelectedCredentials] = useState([])
    const [showCredentialsList, setShowCredentialsList] = useState(false)
    const [createNewCredential, setCreateNewCredential] = useState(false)
    const [deleteCredential, setDeleteCredential] = useState(null)
    const [sharingError, setSharingError] = useState(null)
    const [inputError, setInputError] = useState(null)

    const {showFeedback,} = useFeedbackMessage();
    const credentialsListRef = useRef(null)
    ClickOutside(credentialsListRef, setShowCredentialsList)

    const usersCredentials = credentialsData.find((c) => c.facebook_user_id === fbUser?.id)
    const usersBusinessId = usersCredentials?.business_id
    const credentialSelected = selectedCredential.length !== 0
    const selectedCredentialValid = selectedCredential.is_valid
    const hasZeroQuotas = selectedCredential.quota_left <= 0

    useEffect(() => {
        if (!!fbLogin && !usersCredentials && !credentialSelected) createNewInstagramCredentials()
    }, [fbLogin, usersCredentials])

    useEffect(() => {
        if (!!fbLogin && !!usersBusinessId && !credentialSelected) updateInstagramCredentials()
    }, [fbLogin, usersBusinessId])

    useEffect(() => {
        if (!selectedCredentialValid && usersCredentials) setSelectedCredentials(usersCredentials)
    }, [selectedCredentialValid])

    useEffect(() => {
        if (createNewCredential) setCreateNewCredential(false)
        if (deleteCredential) setDeleteCredential(false)
    }, [showCredentialsList])

    useEffect(() => {
        if (!inputError) return
        if (inputError) {
            if (caption.length >= 5 && credentialSelected && selectedCredentialValid) setInputError(false)
        }
    }, [inputError, selectedCredential, selectedCredentialValid])
    
    const createNewInstagramCredentials = async () => {

        const accessTokenBody = {
            instagram_user_access_token: fbLogin.access_token,
            expires_in: fbLogin.expires_in
        }

        const {error} = await Backend.post("/socials/credentials/instagram", JSON.stringify(accessTokenBody), {})
        if (error) {
            console.log("Failed to create new credentials,", error)
            showFeedback("warning", "Failed to create new Instagram credentials")
        } else {
            mutateCredentials(credentialsData)
            setCreateNewCredential(false)
            console.log("New instagram credentials created")
        }
    }

    const updateInstagramCredentials = async () => {
        const accessTokenBody = {
            instagram_user_access_token: fbLogin.access_token, 
            expires_in: fbLogin.expires_in,
        }
        const {error} = await Backend.put("/socials/credentials/instagram/" + usersBusinessId, JSON.stringify(accessTokenBody))
        if (error) {
            console.log("Failed to update Instagram credentials,", error)
            showFeedback("warning", "Failed to update Instagram credentials")
        } else {
            await mutateCredentials(credentialsData)
            if (usersCredentials) setSelectedCredentials(usersCredentials)
            console.log("Instagram credentials updated");
        }
    }

    const deleteInstagramCredentials = async () => {
        const {error} = await Backend.delete("/socials/credentials/instagram/" + deleteCredential.business_id, {})
        if (error) {
            console.log("Failed to delete Instagram credentials,", error)
            showFeedback("warning", "Failed to delete Instagram credentials")
        } else {
            console.log("Instagram credentials deleted", deleteCredential.business_id)
        }

        if (credentialsData.length === 1) {
            mutateCredentials([])
        } else {
            mutateCredentials(data)
        }

        if (selectedCredential.business_id === deleteCredential.business_id) {
            setSelectedCredentials([])
        }
        setDeleteCredential(null)
    }

    const onShareToInstagram = async () => {

        if (!selectedCredential) {
            setInputError(true)
            return
        }

        if (selectedCredential && !selectedCredential.is_valid) {
            setInputError(true)
            return
        }

        if (hasZeroQuotas) {
            setInputError(true)
            return
        }

        const businessId = selectedCredential.business_id

        const shareToInstagramBody = {
            media_type: mediaType.toUpperCase(),
            video_url: video.download_link,
        }

        if (mediaType === "reels") shareToInstagramBody.share_to_feed = shareToFeed
        if (caption) shareToInstagramBody.caption= caption

        console.log(JSON.stringify(shareToInstagramBody));

        const {error} = await Backend.post("/socials/share/instagram/" + businessId, JSON.stringify(shareToInstagramBody))
        if (error) {
            setSharingError(error)
            console.error("Failed to post", error)
        } else {
            onClose()
            openShareTable()
            console.log("Sharing to Instagram")
        } 
    }

    const onSelectCredentials = (credentials) => {
        setSelectedCredentials(credentials)
        setShowCredentialsList(false)
    }

    const onDeleteCredentials = (e, credentials) => {
        e.stopPropagation()
        setDeleteCredential(credentials)
    }

    const deleteCredentialConfirmation = deleteCredential && (
        <div className={classNames("delete-credentials", {"show": deleteCredential})}>
            <div className="delete-credentials-title">Confirm delete this credentials?</div>
            <div className="credentials-details">
                <div className="credentials-details-img">
                    {deleteCredential.profile_picture_url ? (<img src="" alt="img" />) : (
                        <ImUser/>
                    )}
                </div>
                <div className="credentials-details-username-id">
                    <div className="credentials-details-username">
                        <div>Username</div>
                        <span>:</span>
                        {deleteCredential.instagram_username}
                    </div>
                    <div className="credentials-details-id">
                        <div>Business id</div>
                        <span>:</span>
                        {deleteCredential.business_id}
                    </div>
                </div>
            </div>
            <div className="delete-credentials-btn">
                <button 
                    onClick={deleteInstagramCredentials}
                    className="red-btn small-btn">
                    Yes
                </button>
                <button 
                    onClick={() => setDeleteCredential(false)} 
                    className="small-btn">
                    No
                </button>
            </div>
        </div>
    )

    const instagramCredentialsList = credentialsData.length !==0 ? (
        <div className="credentials-list-cont">
            <div className={classNames("credentials-list", {"empty-list": credentialsData.length === 0})}>
                {credentialsData.map((c) => {
                    return (
                        <div 
                            key={c.business_id} 
                            onClick={() => onSelectCredentials(c)} 
                            className={classNames("credentials-details-single in-list", {
                                "active": c.business_id === selectedCredential.business_id,
                            })}>
                            <div className="credentials-details">
                                <div className="credentials-details-img">
                                    {c.profile_picture_url ? (<img src="" alt="img" />) : (
                                        <ImUser/>
                                    )}
                                </div>
                                <div className="credentials-details-username-id">
                                    <div className="credentials-details-username">
                                        <div>Username</div>
                                        <span>:</span>
                                        {c.instagram_username}
                                    </div>
                                    <div className="credentials-details-id">
                                        <div>Business id</div>
                                        <span>:</span>
                                        {c.business_id}
                                    </div>
                                </div>
                                <FaTrashAlt onClick={(e) => onDeleteCredentials(e, c)} className="delete-credentials-btn"/>
                            </div>
                            {!c.is_valid && <div className="credentials-expired">This credential is expired. Please select an updated credential.</div>}
                        </div>
                    )
                })}
            </div>
            {fbLogin && (
                <div className="login-to-select-credentials">
                    {credentialsData.length === 0 && "Login to Facebook to select or create credential"}
                </div>
            )}
            {deleteCredentialConfirmation}
        </div>
    ) : (
        <div className="credentials-list-empty">
            Login to Facebook to create credential
        </div>
    )
    
    const instagramCredentials = (
        <>
            <div className="instagram-credentials">
                <div className="logged-in-title">
                    Instagram credentials
                </div>
                <div className="credentials-details-single">
                    {credentialSelected ? (
                        <>
                            <div className="credentials-details">
                                <div className="credentials-details-img">
                                    {selectedCredential.profile_picture_url ? (<img src="" alt="img" />) : (
                                        <ImUser/>
                                    )}
                                </div>
                                <div className="credentials-details-username-id">
                                    <div className="credentials-details-username">
                                        <div>Username</div>
                                        <span>:</span>
                                        {selectedCredential.instagram_username}
                                    </div>
                                    <div className="credentials-details-id">
                                        <div>Business id</div>
                                        <span>:</span>
                                        {selectedCredential.business_id}
                                    </div>
                                </div>
                            </div>
                            {hasZeroQuotas && <div className="credential-zero-quotas">This credential has zero share quotas</div>}
                            {!selectedCredentialValid && <div className="credentials-expired">This credential is expired. Please select a valid credential.</div>}
                        </>
                    ) : (
                        <div className="no-credentials-selected">
                            No Credentials selected
                        </div>
                    )}
                    <div ref={credentialsListRef} className="list-of-credentials">
                        <div onClick={() => setShowCredentialsList(!showCredentialsList)} className={classNames("list-of-credentials-btn", {"is-open": showCredentialsList})}>
                            List of credentials
                            <TiArrowSortedDown/>
                        </div>
                        {showCredentialsList && instagramCredentialsList}
                    </div>
                </div>
                {inputError && showErrorMessage("Valid credential is required", (!credentialSelected || !selectedCredentialValid))}
            </div>
        </>
    )

    const shareVideoForm = (
        <div className="share-video-form">
            <div className="input-container">
                <label htmlFor="" className="input-title">Media type</label>
                <SmallDropdown 
                    value={mediaType}
                    options={["reels", "stories"]}
                    onChange={(v) => setMediaType(v)}/>
            </div>
            {mediaType === "reels" && (
                <div className="input-container">
                    <label className="input-title">Caption</label>
                    <input
                        type="text"
                        onChange={(e) => setCaption(e.target.value)}
                        value={caption}
                        placeholder="Min 5 characters"
                        className="share-video-title-input"
                        />
                    <div className="share-original-title">
                        <span className="original-video-desc">Original video title: </span>
                        <span className="original-video-desc">{video.description}</span>
                        <span><RiFileCopyLine onClick={() => setCaption(video.description)} className="use-original-title"/></span>
                    </div>
                </div>
            )}
            {mediaType === "reels" && (
                <div className="input-container-toggle">
                    <label className="toggle-title">Share to feed</label>
                    <div onClick={() => setShareToFeed(!shareToFeed)} className={classNames("toggle-switch", {"active": shareToFeed})}>
                        <div className="toggle-button"></div>
                    </div>
                </div>
            )}
            {showErrorMessage("Failed to post. " + sharingError + ". Please try again later.", sharingError)}
        </div>
    ) 

    return (
        <>
            {instagramCredentials}
            {shareVideoForm}
            <div className="confirm-cancel-btn-cont right">
                <button onClick={onShareToInstagram} disabled={inputError} className="share-to-social-media-btn green-btn">Share to Instagram</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </>
    )
}

export default ShareToInstagram