import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { graphs } from "./StatisticGraphs";

registerLocale("sv", sv)
setDefaultLocale("sv")

const Config = {

    getBackend: (league=undefined) => (
        Config.api + (league || Config.activeLeague.path)
    ),
    api: undefined, // Must always set by appTargetOverrides
    leagues: undefined, // Must always set by appTargetOverrides
    activeLeague: undefined, // Always set on application load in App.js
    webHref: "https://fotbollplay.se",
    sentryDSN: "",
    
    isDevEnvironment: process.env.NODE_ENV === "development",
    buildVersion: process.env.REACT_APP_BUILD_VERSION || "localbuild",

    meta: {
        title: "Fotboll Play Backoffice",
        description: "Find, manage, edit and upload clips, assets and events to the Fotboll Play video platform",
        keywords: "soccer,highlights,football,video,mam",
    },
    defaultPath: "/library/videos",
    showFullAssetsOnMatchScreen: true,
    hideVideoActionOptions: false, // unused for now
    fotbollkontoLoginEnabled: false,

    uploadDefaults: {
        publishPeriod: 0,
    },

    streamProfileMapping: {
        "SD": "Low (480p 1.75mb/s)",
        "HD": "HD (720p 2.75mb/s)",
        "FHD": "Full HD (1080p 5mb/s)",
    },
    getStreamProfileKey: (v) => {
        for (const [key, value] of Object.entries(Config.streamProfileMapping))
            if (value === v) return key
        return Config.streamDefaults.profile
    },
    streamDefaults: {
        publishPeriod: 1,
        profile: "FHD", // Make sure this is actually in the list of profiles above
        streamType: "RTMP"
    },

    // Summer format: 2020  Winter format: 2020/21
    // We assume that we have continuous data, so every season from then until now
    firstSeason: "2018",
    seasons: [], // Populated at end of file - do not override directly
    // seasons: ["2020", "2019", "2018"], // Example of a summer season
    // seasons: ["2020/21", "2019/20", "2018/19"], // Example of a winter season
    seasonInterval: (season) => {
        const start = season.length === 4 ?
            new Date(season) : // Summer season
            new Date(season.substring(0, 4) + "-07-01"); // Winter season, starts July 1st
        const end = new Date(start);
        end.setFullYear(end.getFullYear() + 1);
        return [start, end];
    },

    premiereProPlugin: isPremierProIframe(), // TODO Not yet used in bulk download?
    roundsPerSeason: 30,
    maximumInlineExportSeconds: 180,  // Should be a multiple of 4
    maximumExportSeconds: 180,  // Should be a multiple of 4
    paddingDuringEditing: 8, // Seconds, this is low on SEF due to Telenor. Most segments arent available.
    paddingDuringExport: 8,  // Seconds, this is low on SEF due to Telenor. Most segments arent available.
    association: "general",
    statisticGraphs: null,

    // TODO Replace with a more general translation lib
    categories: {
        "press conference": "Presskonferens",
        "match": "Match",
        "training": "Träning",
        "practice matches": "Träningsmatcher",
        "preseason": "Försäsong",
        "highlights": "Sammandrag",
        "studio program": "Studioprogram",
        "reportage": "Reportage",
        "series": "Serie",
        "interview": "Intervju",
        "club meeting": "Klubbmöte",
        "archive": "Arkiv",
        "sustainability": "Hållbarhet",
        "education": "Utbildning",
    },
    // TODO better naming
    rollCategories: {
        "placeholder": "Platshållare",
        "preroll": "preroll",
        "postroll": "postroll",
    }
}

const appTargetOverrides = {
    "prod-sef": {
        api: "https://api.fotbollplay.se/",
        webHref: "https://fotbollplay.se",
        fotbollkontoLoginEnabled: true,
        leagues: [
            { displayName: "allsvenskan", path: "allsvenskan" },
            { displayName: "superettan", path: "superettan" },
        ],
        sentryDSN: "https://5c7f571114be458bb71b0a716969402c@o543475.ingest.sentry.io/4503965441785856",
        association: "SEF",
        showFullAssetsOnMatchScreen: false,
        statisticGraphs: graphs["SEF"],
    },
    "prod-shl": {
        api: "https://api.forzify.com/",
        firstSeason: "2021/22",
        leagues: [
            { displayName: "SHL", path: "shl" }
        ],
        meta: {
            title: "SHL Mam",
            description: "Find, manage, edit and upload clips, assets and events to the SHL video platform",
            keywords: "hockey,highlights,ice hockey,video,mam",
        },
        sentryDSN: "https://c1f5b469ffd541dfa13c0c7558ae1de6@o1395711.ingest.sentry.io/4504293196300288",
        maximumInlineExportSeconds: 900,
        paddingDuringExport: 450,
        association: "SHL",
        streamDefaults: {
            publishPeriod: 1,
            profile: "sportality_1080p",
            streamType: "SRT"
        },
        streamProfileMapping: {
            "sportality_1080p": "Sportality (1080p 15mb/s)",
        },
    },
    "staging-sef": {
        api: "https://staging-api.forzify.com/",
        webHref: "https://fotboll-play-staging.forzify.com",
        fotbollkontoLoginEnabled: true,
        leagues: [
            { displayName: "allsvenskan", path: "allsvenskan" },
            { displayName: "superettan", path: "superettan" },
        ],
        association: "SEF",
        showFullAssetsOnMatchScreen: false,
        statisticGraphs: graphs["SEF"],
    },
    "prod-sfl": {
        api: "https://api.forzify.com/",
        webHref: "https://highlights.svenskafutsalligan.se",
        firstSeason: "2020/21",
        categories: {
            "highlights": "Sammandrag",
        },
        uploadDefaults: {
            publishPeriod: 1,
        },
        leagues: [
            { displayName: "svenskafutsalligan", path: "svenskafutsalligan" }
        ],
        association: "SFL",
        statisticGraphs: graphs["SFL"],
    },
    "prod-ntf": {
        api: "https://api.forzify.com/",
        webHref: "https://highlights.eliteserien.no",
        leagues: [
            { displayName: "eliteserien", path: "eliteserien" },
            { displayName: "obosligaen", path: "obosligaen" }
        ],
        association: "NTF",
        statisticGraphs: graphs["NTF"],
    },
    "prod-toppserien": {
        api: "https://api.forzify.com/",
        webHref: "https://highlights.toppserien.no",
        leagues: [
            { displayName: "toppserien", path: "toppserien" },
        ],
        meta: {
            title: "Toppserien MAM",
            description: "Find, manage, edit and upload clips, assets and events to the Toppserien video platform",
            keywords: "woman,soccer,highlights,football,video,mam",
        },
        association: "toppserien",
        statisticGraphs: graphs["toppserien"],
    },
    "demo-tomas": {
        api: "https://demo-api.forzify.com/",
        leagues: [
            { displayName: "demo-l1", path: "demo-tomas-l1" },
            { displayName: "demo-l2", path: "demo-tomas-l2" },
        ],
        meta: {
            title: "mam-demo-tomas",
            description: "Find, manage, edit and upload clips, assets and events to the Fotboll Play video platform",
            keywords: "soccer,highlights,football,video,mam",
        },
        maximumInlineExportSeconds: 900,
        paddingDuringExport: 450,
        streamDefaults: {
            publishPeriod: 1,
            profile: "sportality_1080p",
            streamType: "SRT"
        },
        streamProfileMapping: {
            "sportality_1080p": "Sportality (1080p 15mb/s)",
        },
        association: "SEF",
        // TODO add SEF statistic to demo-tomas for testing/showcase
        statisticGraphs: graphs["SEF"],
    },
    "demo-ragnar": {
        api: "https://demo-api.forzify.com/",
        leagues: [
            { displayName: "demo-l1", path: "demo-ragnar-l1" },
        ],
        meta: {
            title: "Demo Ragnar Mam",
            description: "Find, manage, edit and upload clips, assets and events to the Forzify video platform",
            keywords: "highlights,football,video,mam",
        },
        maximumInlineExportSeconds: 900,
        paddingDuringExport: 450,
        association: "toppserien",
        statisticGraphs: graphs["toppserien"],
    },
    "demo-shl": {
        api: "https://demo-api.forzify.com/",
        firstSeason: "2021/22",
        leagues: [
            { displayName: "demo-shl", path: "demo-shl" }
        ],
        meta: {
            title: "SHL Mam",
            description: "Find, manage, edit and upload clips, assets and events to the SHL video platform",
            keywords: "hockey,highlights,ice hockey,video,mam",
        },
        maximumInlineExportSeconds: 900,
        paddingDuringExport: 450,
        association: "SHL",
        streamDefaults: {
            publishPeriod: 1,
            profile: "sportality_1080p",
            streamType: "SRT"
        },
        streamProfileMapping: {
            "sportality_1080p": "Sportality (1080p 15mb/s)",
        },
    },
    "demo": {
        api: "https://demo-api.forzify.com/",
        leagues: [
            { displayName: "j1 league", path: "jpn" },
            { displayName: "eredevise", path: "nld" },
            { displayName: "nike liga", path: "svk" },
        ],
        meta: {
            title: "Demo Mam",
            description: "Find, manage, edit and upload clips, assets and events to the football video platform",
            keywords: "soccer,highlights,football,video,mam",
        },
        paddingDuringExport: 450,
        association: "EL",
    },
    "localhost": {
        api: "https://api.forzifyinfra.com:8443/",
        fotbollkontoLoginEnabled: true,
        leagues: [
            { displayName: "allsvenskan", path: "allsvenskan" },
            { displayName: "superettan", path: "superettan" },
        ]
    },
}

const override = process.env.REACT_APP_TARGET || "localhost";
Object.assign(Config, appTargetOverrides[override]);

// Populate the seasons list
// It's OK if Config.firstSeason is f ex 2023/24, parseInt then yields 2023
const currentYear = new Date().getFullYear()
// TODO need to be renamed
// before April, current season is still using last year
let springCurrentYear = new Date ().getMonth() >= 3 ? currentYear : currentYear - 1
// temporarily add 2024 for NTF
if (Config.association === "NTF") springCurrentYear = currentYear
for (let year = parseInt(Config.firstSeason, 10); year <= springCurrentYear; ++year) {
    if (Config.firstSeason.length === 4) { // Season starts in spring
        Config.seasons.unshift(year.toString())
        continue
    }
    if (year === currentYear && new Date().getMonth() < 6) break
    Config.seasons.unshift(`${year}/${(year + 1).toString().substring(2)}`)
}

function isPremierProIframe () {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.get("adobePPro") !== null) return true;
    // Technically just checks for any iframe - but assumptions
    try {
        return window.self !== window.top
    } catch { return true }
}

export default Config
