import React, {useState, useEffect, useRef} from "react"
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import { showErrorMessage } from "../../utility/Utilities";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import { ClickOutside } from "../../utility/Utilities";
// import SmallDropdown from "../../components/SmallDropdown";
import { RiFileCopyLine } from "react-icons/ri";
import {TiArrowSortedDown} from "react-icons/ti";
import { ImUser } from "react-icons/im";
import {FaTrashAlt} from "react-icons/fa";
import "./ShareVideo.css"
import classNames from "classnames";

function ShareToFacebook ({fbLogin, fbUser, onClose, video, openShareTable}) {

    const { data, mutate: mutateCredentials } = useBackend("/socials/credentials/facebook")
    const credentialsData = data || []

    const mediaType = "video"
    // const [mediaType, setMediaType] = useState("video")
    const [description, setDescription] = useState("")
    const [selectedCredential, setSelectedCredential] = useState([])
    const [showCredentialsList, setShowCredentialsList] = useState(false)
    const [createNewCredential, setCreateNewCredential] = useState(false)
    const [deleteCredential, setDeleteCredential] = useState(null)
    const [sharingError, setSharingError] = useState(null)
    const [inputError, setInputError] = useState(false)

    const {showFeedback,} = useFeedbackMessage();
    const credentialsListRef = useRef(null)
    ClickOutside(credentialsListRef, setShowCredentialsList)
   
    const usersCredential = credentialsData.find((c) => c.facebook_user_id === fbUser?.id)
    const usersFacebookId = usersCredential?.facebook_user_id
    const credentialSelected = selectedCredential.length !== 0
    const selectedCredentialValid = selectedCredential.is_valid

    useEffect(() => {
        if (!!fbLogin && !usersCredential && !credentialSelected) createNewFacebookCredentials()
    }, [usersCredential])

    useEffect(() => {
        if (!!fbLogin && !!usersFacebookId && !credentialSelected) updateFacebookCredentials()
    }, [fbLogin, usersFacebookId])

    useEffect(() => {
        if (createNewCredential) setCreateNewCredential(false)
        if (deleteCredential) setDeleteCredential(false)
    }, [showCredentialsList])

    useEffect(() => {
        if (!inputError) return
        if (inputError) {
            if (credentialSelected && selectedCredentialValid) setInputError(false)
        }
    }, [inputError, selectedCredential, selectedCredentialValid])
    
    const createNewFacebookCredentials = async () => {

        const accessTokenBody = {
            facebook_user_access_token: fbLogin.access_token,
            expires_in: fbLogin.expires_in
        }

        const {error} = await Backend.post("/socials/credentials/facebook", JSON.stringify(accessTokenBody), {})
        if (error) {
            console.log("Failed to create new credentials,", error)
            showFeedback("warning", "Failed to create new Facebook credentials")
        } else {
            mutateCredentials(credentialsData)
            setCreateNewCredential(false)
            console.log("New Facebook credentials created")
        }
    }

    const updateFacebookCredentials = async () => {
        const accessTokenBody = {
            facebook_user_access_token: fbLogin.access_token, 
            expires_in: fbLogin.expires_in,
        }
        const {error} = await Backend.put("/socials/credentials/facebook/" + usersFacebookId, JSON.stringify(accessTokenBody))
        if (error) {
            console.log("Failed to update Facebook credentials,", error)
            showFeedback("warning", "Failed to update Facebook credentials")
        } else {
            await mutateCredentials(credentialsData)
            if (usersCredential) setSelectedCredential(usersCredential)
            console.log("Facebook credentials updated");
        }
    }

    const deleteFacebookCredentials = async () => {
        const accessTokenBody = {
            facebook_user_access_token: fbLogin.access_token, 
            expires_in: fbLogin.expires_in,
        }

        const {error} = await Backend.delete("/socials/credentials/facebook/" + deleteCredential.facebook_user_id, JSON.stringify(accessTokenBody))
        if (error) {
            console.log("Failed to delete Facebook credentials,", error)
            showFeedback("warning", "Failed to delete Facebook credentials")
        } else {
            console.log("Facebook credentials deleted", deleteCredential.facebook_user_id)
        }

        if (credentialsData.length === 1) {
            mutateCredentials([])
        } else {
            mutateCredentials(data)
        }

        if (selectedCredential.facebook_user_id === deleteCredential.facebook_user_id) {
            setSelectedCredential([])
        }

        setDeleteCredential(null)
    }

    const onShareToFacebook = async () => {

        if (!credentialSelected) {
            setInputError(true)
            return
        }

        if (!selectedCredentialValid) {
            setInputError(true)
            return
        }

        const facebookId = selectedCredential.facebook_user_id

        const shareToFacebookBody = {
            media_type: mediaType.toUpperCase(),
            file_url: video.download_link,
        }

        if (description) shareToFacebookBody.description = description

        const {error} = await Backend.post("/socials/share/facebook/" + facebookId, JSON.stringify(shareToFacebookBody))
        if (error) {
            setSharingError(error)
            console.error("Failed to post", error)
        } else {
            openShareTable()
            console.log("Sharing to Facebook", shareToFacebookBody)
        }
        onClose()
    }

    const onSelectCredentials = (credentials) => {
        setSelectedCredential(credentials)
        setShowCredentialsList(false)
    }

    const onDeleteCredentials = (e, credentials) => {
        e.stopPropagation()
        setDeleteCredential(credentials)
    }

    const deleteCredentialConfirmation = (
        <div className={classNames("delete-credentials", {"show": deleteCredential})}>
            <div className="delete-credentials-title">Confirm delete this credentials?</div>
            <div className="credentials-details">
                <div className="credentials-details-img">
                    {deleteCredential?.profile_picture_url ? (<img src={deleteCredential?.profile_picture_url} alt="img" />) : (
                        <ImUser/>
                    )}
                </div>
                <div className="credentials-details-username-id">
                    <div className="credentials-details-username">
                        <div>Username</div>
                        <span>:</span>
                        {deleteCredential?.facebook_email_address}
                    </div>
                    <div className="credentials-details-id">
                        <div>Business id</div>
                        <span>:</span>
                        {deleteCredential?.facebook_user_id}
                    </div>
                </div>
            </div>
            <div className="delete-credentials-btn">
                <button 
                    onClick={deleteFacebookCredentials}
                    className="red-btn small-btn">
                    Yes
                </button>
                <button 
                    onClick={() => setDeleteCredential(false)} 
                    className="small-btn">
                    No
                </button>
            </div>
        </div>
    )

    const facebookCredentialsList = credentialsData.length !==0 ? (
        <div className="credentials-list-cont">
            <div className={classNames("credentials-list", {"empty-list": credentialsData.length === 0})}>
                {credentialsData.map((c) => {
                    return (
                        <div 
                            key={c.facebook_user_id} 
                            onClick={() => onSelectCredentials(c)} 
                            className={classNames("credentials-details-single in-list", {
                                "active": c.facebook_user_id === selectedCredential.facebook_user_id,
                            })}>
                            <div className="credentials-details">
                                <div className="credentials-details-img">
                                    {c.profile_picture_url ? (<img src={c.profile_picture_url} alt="img" />) : (
                                        <ImUser/>
                                    )}
                                </div>
                                <div className="credentials-details-username-id">
                                    <div className="credentials-details-username">
                                        <div>Username</div>
                                        <span>:</span>
                                        {c.facebook_email_address}
                                    </div>
                                    <div className="credentials-details-id">
                                        <div>Business id</div>
                                        <span>:</span>
                                        {c.facebook_user_id}
                                    </div>
                                </div>
                                <FaTrashAlt onClick={(e) => onDeleteCredentials(e, c)} className="delete-credentials-btn"/>
                            </div>
                            {!c.is_valid && <div className="credentials-expired">This credential is expired. Please select an updated credential.</div>}
                        </div>
                    )
                })}
            </div>
            {fbLogin && (
                <div className="login-to-select-credentials">
                    {credentialsData.length === 0 && "Login to Facebook to select or create credential"}
                </div>
            )}
            {deleteCredentialConfirmation}
        </div>
    ) : (
        <div onClick={createNewFacebookCredentials} className="credentials-list-empty">
            Login to Facebook to create credential
        </div>
    )
    
    const facebookCredentials = (
        <div className="instagram-credentials">
            <div className="logged-in-title">
                Facebook credentials
            </div>
            <div className="credentials-details-single">
                {credentialSelected ? (
                    <>
                        <div className="credentials-details">
                            <div className="credentials-details-img">
                                {selectedCredential.profile_picture_url ? (
                                    <img src={selectedCredential.profile_picture_url} alt="img" />
                                ) : (
                                    <ImUser/>
                                )}
                            </div>
                            <div className="credentials-details-username-id">
                                <div className="credentials-details-username">
                                    <div>Username</div>
                                    <span>:</span>
                                    {selectedCredential.facebook_email_address}
                                </div>
                                <div className="credentials-details-id">
                                    <div>Facebook id</div>
                                    <span>:</span>
                                    {selectedCredential.facebook_user_id}
                                </div>
                            </div>
                        </div>
                        {!selectedCredentialValid && <div className="credentials-expired">This credential is expired. Login to Facebook to update the credentials.</div>}
                    </>
                ) : (
                    <div className="no-credentials-selected">
                        No Credentials selected
                    </div>
                )}
                <div ref={credentialsListRef} className="list-of-credentials">
                    <div onClick={() => setShowCredentialsList(!showCredentialsList)} className={classNames("list-of-credentials-btn", {"is-open": showCredentialsList})}>
                        List of credentials
                        <TiArrowSortedDown/>
                    </div>
                    {showCredentialsList && facebookCredentialsList}
                </div>
            </div>
            {inputError && showErrorMessage("Valid credential is required", (!credentialSelected || !selectedCredentialValid))}
        </div>
    )

    const shareVideoForm = (
        <div className="share-video-form">
            <div className="input-container">
                <label htmlFor="" className="input-title">Media type</label>
                <div>Video</div>
                {/* <SmallDropdown 
                    value={mediaType}
                    options={["reels", "stories", "video"]}
                    onChange={(v) => setMediaType(v)}/> */}
            </div>
            <div className="input-container">
                <label className="input-title">Description</label>
                <input
                    type="text"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    className="share-video-title-input"
                    />
                <div className="share-original-title">
                    <span className="original-video-desc">Original video title: </span>
                    <span className="original-video-desc">{video.description}</span>
                    <span><RiFileCopyLine onClick={() => setDescription(video.description)} className="use-original-title"/></span>
                </div>
            </div>
            {showErrorMessage("Failed to post. " + sharingError + ". Please try again later.", sharingError)}
        </div>
    ) 

    return (
        <>
            {facebookCredentials}
            {shareVideoForm}
            <div className="confirm-cancel-btn-cont right">
                <button onClick={onShareToFacebook} disabled={inputError} className="share-to-social-media-btn green-btn">Share to Facebook</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </>
    )
}

export default ShareToFacebook