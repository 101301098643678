import React, {useState, useEffect, useRef} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Config from "../../utility/Config";
import NavLink from "../../components/NavLink";
import { useCheckGraphicPackagesPermission, useUpdateSearchParams } from "../../utility/Utilities";
import {useLoginSession} from "../../stores/loginSession";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import "./Header.css";
import "./FeedbackMessage.css";
import {useCompilation} from "../../stores/compilation";
import { useGetLeagueLogo } from "../../utility/useGetLeagueLogo";
import Exports from "../Exports/Exports";
import {useExportsModal} from "../../stores/ExportsModalContext";
import {useMediaQuery} from "react-responsive";
import classNames from "classnames";
import {FiEdit3, FiMenu} from "react-icons/fi";
import { IoMdArrowDropright } from "react-icons/io";
import {FaUser} from "react-icons/fa";
import { useTagging } from "../../stores/VideoTagging";
import {IoArrowBack} from "react-icons/io5";
import { useCheckIsAdmin } from "../../utility/UserGroups";

export default function () {

    const headerRef = useRef();
    const navRef = useRef();
    const navigate = useNavigate();

    const {
        username,
        allowUploading,
        allowSchedulingStreams,
        allowEditingTeams,
        allowEditingUsergroups,
        allowVideoExport,
    } = useLoginSession();
    
    const [, updateSearchParams] = useUpdateSearchParams();
    const {feedbackMsg, feedbackMessage} = useFeedbackMessage();
    const {compilationInfo, playlistId: compilationId} = useCompilation();
    const {taggingProps} = useTagging()

    const [showAdminNav,setShowAdminNav] = useState(false)
    const [navDropdown, setNavDropdown] = useState(false);
    const {isOpen: isExportsModalOpen, setIsOpen: setIsExportsModalOpen} = useExportsModal()

    const [,isAdmin] = useCheckIsAdmin()
    const baseName = window.location.pathname.split("/")[1];

    const leagueWithStatistics = ["SEF", "NTF", "toppserien", "SFL"]
    const showStatisticPage = leagueWithStatistics.includes(Config.association)
    const showGraphicPackagePage = useCheckGraphicPackagesPermission()

    const mobileDevice = useMediaQuery({maxWidth: 768})
    const dropdownHeader = useMediaQuery({maxWidth: 960})
    
    const changeLeague = (path) => {
        window.location = window.location.pathname.replace(`${baseName}`, `${path}`);
    }

    const handleNavDropdown = () => {
        if (showAdminNav) setShowAdminNav(false);
        setNavDropdown(!navDropdown);
    }

    const openExportModal = () => {
        setIsExportsModalOpen(true)
        handleNavDropdown()
    }

    const onNewUpload = () => {
        if (!pathname.startsWith("/library/videos")) {
            navigate("/library/videos?videotype=uploads&editing=upload_form")
        } 
        else {
            // when user is in library but not in club content
            if (searchParams.get("videotype") !== "uploads") updateSearchParams("videotype", "uploads")
            // when in club content, only update the upload form param and doesn't interrupt other existing params / filters
            updateSearchParams("editing", "upload_form")
        }
        handleNavDropdown()
    }

    const onShowAdminNav = (show=false) => {
        if (dropdownHeader) return
        setShowAdminNav(show)
    }

    const onClickShowAdminNav = () => {
        if (!dropdownHeader) return
        setShowAdminNav(!showAdminNav)
    }

    useEffect(() => {
        const navHeight = navRef.current.getBoundingClientRect().height;
        if (navDropdown) {
            headerRef.current.style.height = `${navHeight}px`;
        } else {
            headerRef.current.style.height = "0px";
        }
    }, [navDropdown]);

    const headerLeaguesLogos = (
        <div className="header-league-cont">
            {Config.leagues.map((item) => {
                return (
                    <div  
                        key={item.path}
                        onClick={() => changeLeague(item.path)}
                        className={`header-logo ${baseName === item.path ? "header-active" : ""}`}
                        >
                        <img src={useGetLeagueLogo(item.path)[1]} alt="logo" className="league-header-logo"/>
                        <div className="header-league">{item.displayName}</div>
                    </div>
                )
            })}
        </div>
    );

    const [searchParams] = useSearchParams();
    const editingParams = searchParams.get("editing");
    const playlistInParams = editingParams && editingParams.includes(compilationId);
    const newUploadActive = searchParams.get("editing") === "upload_form";

    let {pathname} = useLocation();

    const updateEditingAndUrl = () => {
        if (pathname.startsWith("/library")) {
            updateSearchParams("editing", "playlist_" + compilationId);
        } else {
            pathname = Config.defaultPath;
            navigate(pathname + "?editing=playlist_" + compilationId);
        }
    }

    const compilationHeader = compilationInfo.title && (
        <li 
            className={`compilation-header ${playlistInParams? "compilation-active" : ""}`} 
            onClick={updateEditingAndUrl}>
            <FiEdit3 className="ch-icon"/>
            {compilationInfo.title}
        </li>
    ); 

    // Not logged in yet
    if (!username) {
        return (
            <>
                <div className="header">
                    <div className="nav-menu"><ul ref={navRef} className="navigation"></ul></div>
                </div>
                <div ref={headerRef} className="mobile-nav"></div>
                {feedbackMsg && feedbackMessage}
            </>
        )
    }
    
    const otherNavigation = (
        <div 
            onClick={onClickShowAdminNav}
            onMouseOver={() => onShowAdminNav(true)} 
            onMouseLeave={() => onShowAdminNav(false)} 
            className={classNames("other-navigation-cont", {
                "show-list": showAdminNav,
                "": mobileDevice
            })}>
            <div className="other-header">
                Other
                <IoMdArrowDropright />
            </div>
            <div className="transparent-space"></div>
            <div className="other-navigation-list">
                {showGraphicPackagePage && (
                    <NavLink to="/graphic_packages" onClick={handleNavDropdown}>Graphic packages</NavLink>
                )}
                {allowEditingTeams && (
                    <NavLink to="/teams" onClick={handleNavDropdown}>Teams</NavLink>
                )}
                {showStatisticPage && (
                    <NavLink base="/statistics" to="/statistics" onClick={handleNavDropdown}>Statistics</NavLink>
                )}
                {allowEditingUsergroups && (
                    <NavLink to="/users" onClick={handleNavDropdown}>Users</NavLink>
                )}
                {dropdownHeader && (
                    <div className="admin-nav-back" onClick={onClickShowAdminNav}>
                        <IoArrowBack/>
                        Back
                    </div>
                )}
            </div>
        </div>
    )

    const navigation = (
        <ul ref={navRef} className="navigation">
            {compilationHeader}
            {taggingProps && (
                <NavLink base="/tagging" to={`/tagging?game=${taggingProps.gameId}`} onClick={handleNavDropdown}>Tagging</NavLink>
            )}
            <NavLink base="/library" to="/library/matches" onClick={handleNavDropdown}>Library</NavLink>
            {allowUploading && (
                <li 
                    onClick={onNewUpload} 
                    className={classNames("non-link-header", {"active": newUploadActive})}>
                    New upload
                </li>
            )}
            {allowSchedulingStreams && (
                <NavLink base="/stream" to="/streams" onClick={handleNavDropdown}>Streams</NavLink>
            )}
            {allowVideoExport && (
                <li 
                    onClick={openExportModal} 
                    className={classNames("non-link-header", {"active": isExportsModalOpen})}>
                    Exports
                </li>
            )}
            {otherNavigation}
            <NavLink to="/profile" onClick={handleNavDropdown}>
                <FaUser className="header-user-icon" /> [{username.split("@")[0]}]
            </NavLink>
        </ul>
    )

    return (
        <>
            <div className="header">
                {headerLeaguesLogos}
                <div className="nav-menu">{navigation}</div>
                <div className={classNames("nav-icon-main", {"active": navDropdown})} onClick={handleNavDropdown}><FiMenu/></div>
            </div>
            <div ref={headerRef} className="mobile-nav">{navigation}</div>
            {isExportsModalOpen && <Exports isOpen={isExportsModalOpen} onClose={() => setIsExportsModalOpen(false)}/>}
            {feedbackMsg && feedbackMessage}
        </>
    )
}