import React, {useState, useEffect} from "react"
import ShareToFacebook from "./ShareToFacebook"
import ShareToInstagram from "./ShareToInstagram"
import CustomModal from "../../components/CustomModal"
import classNames from "classnames"
import { IoLogoFacebook } from "react-icons/io5";
import "./ShareVideo.css"
import { capitalizeFirstLetter } from "../../utility/Utilities"

function ShareToMeta ({shareVideo, onClose, openShareTable}) {

    const fbLoaded = window.FB

    const initFb = () => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId            : "1266175214012437",
                autoLogAppEvents : true,
                xfbml            : true,
                status           : true,
                cookie           : true,
                version          : "v17.0"
            });
            console.log("FB loaded");
            onCheckFbLogin()
        }
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        } (document, "script", "facebook-jssdk"));
    }
    
    useEffect(() => {
        initFb()
    }, [])

    useEffect(() => {
        if (fbLoaded) onCheckFbLogin()
    }, [fbLoaded])

    const [fbLogin, setFbLogin] = useState(null)
    const [fbUser, setFbUser] = useState(null)
    const [fbError, setFbError] = useState(null)
    const [loggingOutFb, setLoggingOutFb] = useState(false)
    
    const platform = shareVideo.media
    const sharingToInstagram = platform === "instagram"
    const sharingToFacebook = platform === "facebook"

    const updateFbUser = (accessToken) => {
        if (fbLogin && fbLogin.access_token === accessToken) return
        window.FB.api("/me", {fields: "id,name,email,picture"}, function (response) {
            if (response.error) {
                setFbError(response.error)
            } else {
                setFbUser({
                    id: response.id,
                    name: response.name,
                    picture: `https://graph.facebook.com/${response.id}/picture`
                })
            }
        });
    }

    const updateFbLogin = (accessToken, expiresIn) => {
        if (fbLogin && fbLogin.access_token === accessToken) return
        else {
            setFbLogin({access_token: accessToken, expires_in: expiresIn})
        }
    }

    const onCheckFbLogin = () => {
        window.FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
                console.log(response.status, response);
                const accessToken = response.authResponse.accessToken;
                const expiresIn = response.authResponse.expiresIn;
                updateFbLogin(accessToken, expiresIn)
                updateFbUser(accessToken)
            }
        })
    }

    const onLoginFb = () => {
        const fbScope = sharingToFacebook ? 
            "public_profile, email, pages_show_list, pages_read_engagement, pages_manage_posts" :
            "public_profile, email, instagram_basic, instagram_content_publish, pages_show_list, business_management"
        window.FB.login(function (response) {
            if (response.authResponse) {
                console.log("Successful login:", response);
                const accessToken = response.authResponse.accessToken;
                const expiresIn = response.authResponse.expiresIn;
                updateFbLogin(accessToken, expiresIn)
                updateFbUser(accessToken)
            } else {
                console.log("User cancelled login or no access.");
            }
        }, {scope: fbScope})
    }

    const onLogoutFb = () => {
        window.FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
                window.FB.logout(function (response) {
                    console.log("logged out", response)
                    setFbLogin(null)
                    setFbUser(null)
                })
            }
        })
    }

    const facebookLogin = (
        <div className="user-logged-in">
            <div className="logged-in-title">Logged in as</div>
            {fbLoaded ? (
                <>
                    {fbLogin ? (
                        <div className="share-user-profile">
                            <div className="user-profile-and-name">
                                <img src={fbUser?.picture} alt="FB img" />
                                <div>{fbUser?.name}</div>
                                <button onClick={() => setLoggingOutFb(true)} className="small-btn red-btn">Logout</button>
                            </div>
                            <div className="fb-login-error">
                                {fbError}
                            </div>
                            <div className={classNames("confirm-logout", {"show": loggingOutFb})}>
                                <div className="create-delete-credentials-title">Confirm logout?</div>
                                <div className="create-new-credentials-btn">
                                    <button 
                                        onClick={onLogoutFb}
                                        className="red-btn small-btn">
                                        Logout
                                    </button>
                                    <button 
                                        onClick={() => setLoggingOutFb(false)} 
                                        className="small-btn">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="no-credentials-found">
                                Not logged in to any Facebook account
                            </div>
                            <div className="login-or-create-credentials">
                                <button onClick={onLoginFb} className="login-or-create-credentials-btn fb">
                                    <IoLogoFacebook/>
                                    Login with Facebook
                                </button>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="checking-credentials-loading">
                    <div className="loading-icon-cont">
                        <div className="loading-download-icon">
                        </div>
                    </div>
                    <div className="checking-credentials-text">
                        Loading Facebook
                    </div>
                </div>
            )}
        </div>
    )
    
    const video = shareVideo.video
    let shareToMeta
    
    if (sharingToInstagram) shareToMeta = (
        <ShareToInstagram 
            fbLogin={fbLogin}
            fbUser={fbUser}
            onClose={onClose} 
            video={video}
            openShareTable={openShareTable}/>
    )
        
    if (sharingToFacebook) shareToMeta = (
        <ShareToFacebook 
            fbLogin={fbLogin}
            fbUser={fbUser}
            onClose={onClose} 
            video={video}
            openShareTable={openShareTable}/>
    )

    return (
        <CustomModal isOpen={!!shareVideo} onRequestClose={onClose} className="share-modal">
            <div className="share-video-modal">
                <div className="share-video-title">Share video to {capitalizeFirstLetter(platform)}</div>
                <div className="share-video-and-credentials">
                    <div className="share-video-review">
                        <video src={video.download_link} controls></video>
                    </div>
                    <div className="credentials-and-form">
                        {facebookLogin}
                        {shareToMeta}
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default ShareToMeta